<template>
  <a-table
    :columns="columns"
    :data-source="data"
    :row-key="record => record.id" 
    :pagination="pagination"
    :scroll="Yscroll"
    size="middle"
  />
</template>
<script>

export default {
  name: 'EneReportGroupPointTable',
  props: {
    data: {
      type: Array,
      default: [],
    },
    fatherChangeMethod: {
        type: Function,
        default: null
    },
    Yscroll:{
       type: Object,
       default: { y: 400 },
    }
  }, 
  data() {
    return {
      columns : [
        {
          title: 'Gr1',
          dataIndex: 'gr1',
          width: 100,
          fixed: "left",
        },
        {
          title: 'Gr2',
          dataIndex: 'gr2',
          width: 100,
          fixed: "left",
        },
        {
          title: 'Gr3',
          dataIndex: 'gr3',
          width: 100,
          fixed: "left",
        },
        {
          title: 'TagPoint',
          dataIndex: 'tagPoint',
          width: 90,
        },
        {
          title: this.$t("energy.setting.reportGr.kikiName"),
          dataIndex: 'kikiName',
        },
        {
          title: this.$t("energy.setting.reportGr.pointName"),
          dataIndex: 'signalName',
        },
        // {
        //   title: this.$t("energy.setting.reportGr.dataKind"),
        //   dataIndex: 'dataKind',
        //   width: 90,
        // },
        
      ],
      pagination:false,
    };
  },
  watch:{
    "data":{
      handler(val){
        //this.selectedRowKeys = [];
      },
      deep:true,
      immediate:true,
    },
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>
